import cx from 'classnames';
import Link from 'components/Link/Link';
import Stripe from 'components/Stripe';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { FC, useCallback } from 'react';
import history from 'utils/history';
import CookieNotice from '../../policies/CookieNotice';
import Login from './Login';
import s from './LoginPage.less';
import { AuthContext } from './types';

const LoginPage: FC<{
  redirectUri?: string;
}> = ({ redirectUri = '/' }) => {
  useStyles(s);

  const handleLogin = useCallback(
    (ctx: AuthContext) => {
      if (ctx?.auth && !ctx?.auth.verified && history) {
        // check that the user is verified, if not the redirect to verification page
        history.push('/confirm');
      } else if (ctx?.auth && ctx?.auth.verified && history) {
        history.push(redirectUri);
      }
    },
    [redirectUri, history],
  );

  return (
    <div className={cx(s.form)}>
      <h1 className={s.title} data-test-id="LoginPageTitle">
        Login
      </h1>
      <Stripe />
      <div className={s.instructions}>
        <p>Please log in using your email and password</p>
      </div>
      <Login onSuccess={handleLogin} />

      <p className={s.resetPassword}>
        <Link to="/reset">Forgot password?</Link>
      </p>
      <p className={s.resetPassword}>
        Need an account? <Link to={'/signup'}>Sign up</Link>
      </p>
      <CookieNotice />
    </div>
  );
};

export default LoginPage;
