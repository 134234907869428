import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { FC } from 'react';
import history from 'utils/history';
import Confirm from './Confirm';
import s from './ConfirmPage.less';

const ConfirmPage: FC<{ confirmToken?: string; redirect?: boolean }> = ({
  confirmToken,
  redirect = true,
}) => {
  useStyles(s);
  const handleConfirm = () => {
    if (redirect && history) history.push('/');
  };

  return (
    <div className={s.root} data-testid="confirm-page">
      <Confirm onSuccess={handleConfirm} confirmToken={confirmToken} />
    </div>
  );
};

export default ConfirmPage;
