import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'customer/store/configureStore';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React from 'react';
import AlertBanner from '../../notification/Alert/Banner/Banner';
import actions from './actions';
import s from './CookieNotice.less';

const CookieNotice: React.FC = () => {
  useStyles(s);
  const dispatch = useAppDispatch();
  const { cookiesAccepted } = useAppSelector((state) => ({
    cookiesAccepted: state.policies.cookieNotice.cookiesAccepted,
  }));

  React.useEffect(() => {
    dispatch(actions.get());
  }, [dispatch]);

  const handleAcceptCookies = React.useCallback(() => {
    dispatch(actions.accept());
  }, [dispatch]);

  if (cookiesAccepted) return null;

  return (
    <div className={s.root}>
      <AlertBanner info>
        <p>
          We use cookies for authentication and to improve your experience. By
          continuing to use this website or closing this banner, you accept our
          use of first and third-party cookies{' '}
          <a
            style={{ color: '#fff', textDecoration: 'underline' }}
            href="https://monerium.com/policies/cookie-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
          .
        </p>
        <div className="container">
          <div className="row justify-content-center">
            <Button
              medium
              success
              style={{ margin: 0 }}
              className="col-md-4"
              onClick={handleAcceptCookies}
            >
              Accept cookies
            </Button>
          </div>
        </div>
      </AlertBanner>
    </div>
  );
};

export default CookieNotice;
