import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React from 'react';
import s from './Banner.less';

interface BannerProps {
  className?: string;
  children: React.ReactNode;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
  danger?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  children,
  className = '',
  success = false,
  info = false,
  warning = false,
  danger = false,
  ...otherProps
}) => {
  useStyles(s);

  return (
    <div
      className={cx(s.root, className, {
        [s.success]: success,
        [s.info]: info,
        [s.warning]: warning,
        [s.danger]: danger,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Banner;
